import {
  getEventExtraProperties as CE_getEventExtraProperties,
  initInstanceId,
  getInstanceId,
} from "ce/utils/Analytics/getEventExtraProperties";
import type { License } from "ee/reducers/tenantReducer.types";

const parentContextTypeTokens = ["pkg", "workflow"];

/**
 * Function to check the current URL and return the parent context.
 * For app, function was returning app name due to the way app urls are structured
 * So this function will only return the parent context for pkg and workflow
 * @param location current location object based on URL
 * @returns object {id, type} where type is either pkg or workflow and id is the id of the pkg or workflow
 */
function getParentContextFromURL(location: Location) {
  const pathSplit = location.pathname.split("/");
  let type = parentContextTypeTokens[0];
  const editorIndex = pathSplit.findIndex((path) =>
    parentContextTypeTokens.includes(path),
  );

  if (editorIndex !== -1) {
    type = pathSplit[editorIndex];

    const id = pathSplit[editorIndex + 1];

    return { id, type };
  }
}

let license: License | null = null;

function initLicense(_license: License) {
  license = _license;
}

function getEventExtraProperties() {
  const CE_Properties = CE_getEventExtraProperties();

  const externalLicenseId = license?.id || "";
  const parentContext = getParentContextFromURL(window.location);

  if (externalLicenseId) {
    return {
      ...CE_Properties,
      ...(parentContext ? { parentContext } : {}),
      externalLicenseId,
    };
  }

  return CE_Properties;
}

export { getEventExtraProperties, initInstanceId, getInstanceId, initLicense };
