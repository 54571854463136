import React, { useCallback, useState } from "react";
import { IDEHeaderSwitcher, IDEHeaderTitle } from "@appsmith/ads";

import ModulesSection from "./ModulesSection";
import {
  createMessage,
  HEADER_TITLES,
  MODULES_TITLE,
} from "ee/constants/messages";
import { useSelector } from "react-redux";
import { getShowModuleReference } from "ee/selectors/moduleFeatureSelectors";

const PackageHeaderEditorTitle = ({ title }: { title: string }) => {
  const [active, setActive] = useState(false);

  const showModuleReference = useSelector(getShowModuleReference);

  const closeMenu = useCallback(() => {
    setActive(false);
  }, [setActive]);

  if (showModuleReference) {
    return (
      <IDEHeaderTitle title={createMessage(HEADER_TITLES.PACKAGE_EDITOR)} />
    );
  }

  return (
    <IDEHeaderSwitcher
      active={active}
      prefix={createMessage(MODULES_TITLE)}
      setActive={setActive}
      title={title}
      titleTestId="t-modules-switcher"
    >
      <ModulesSection onItemSelected={closeMenu} />
    </IDEHeaderSwitcher>
  );
};

export default PackageHeaderEditorTitle;
