import React, { useCallback, useEffect, useState } from "react";
import { Divider } from "@appsmith/ads";
import CDApiKey from "./CDApiKey";
import CDEndpoint from "./CDEndpoint";
import {
  GIT_CD_ENDPOINT_DESC,
  GIT_CD_RECONFIGURE_API_KEY_CTA,
  GIT_CD_RECONFIGURE_API_KEY_DESC,
  createMessage,
} from "ee/constants/messages";
import AnalyticsUtil from "ee/utils/AnalyticsUtil";
import noop from "lodash/noop";
import type { GitSettingsTab } from "git/constants/enums";
import CDDisableZone from "./CDDisableZone";
import type { GitBranch } from "git/types";

interface CDExistingProps {
  artifactLastDeployedAt: string | null;
  baseArtifactId: string | null;
  branches: GitBranch[] | null;
  cdApiKey: string | null;
  defaultBranch: string | null;
  isFetchBranchesLoading: boolean;
  isGenerateCDApiKeyLoading: boolean;
  resetGenerateCDApiKey: () => void;
  toggleCDDisableModal: (open: boolean) => void;
  toggleCDReconfigureModal: (open: boolean) => void;
  toggleSettingsModal?: (
    open: boolean,
    tab?: keyof typeof GitSettingsTab,
  ) => void;
}

function CDExisting({
  artifactLastDeployedAt = null,
  baseArtifactId = null,
  branches = null,
  cdApiKey = null,
  defaultBranch = null,
  isFetchBranchesLoading = false,
  isGenerateCDApiKeyLoading = false,
  resetGenerateCDApiKey = noop,
  toggleCDDisableModal = noop,
  toggleCDReconfigureModal = noop,
  toggleSettingsModal = noop,
}: CDExistingProps) {
  const [selectedBranch, setSelectedBranch] = useState<string>();

  const handleClickOnReconfigure = useCallback(() => {
    toggleSettingsModal(false);
    toggleCDReconfigureModal(true);
    AnalyticsUtil.logEvent("GS_CD_GENERATE_KEY_CLICKED", {
      deploymentTool: "others",
      regenerated: true,
    });
  }, [toggleCDReconfigureModal, toggleSettingsModal]);

  useEffect(
    function setInitialBranchOnInit() {
      if (!selectedBranch && defaultBranch) {
        setSelectedBranch(defaultBranch);
      }
    },
    [selectedBranch, defaultBranch],
  );

  return (
    <div className="pt-2" data-testid="t--cd-existing">
      <CDDisableZone
        artifactLastDeployedAt={artifactLastDeployedAt}
        defaultBranch={defaultBranch}
        toggleCDDisableModal={toggleCDDisableModal}
        toggleSettingsModal={toggleSettingsModal}
      />
      <Divider className="d-block mt-6 mb-6" />
      <CDApiKey
        compact
        ctaText={createMessage(GIT_CD_RECONFIGURE_API_KEY_CTA)}
        descText={createMessage(GIT_CD_RECONFIGURE_API_KEY_DESC)}
        isLoading={isGenerateCDApiKeyLoading}
        onClick={handleClickOnReconfigure}
        onUnmount={resetGenerateCDApiKey}
        value={cdApiKey}
      />
      <CDEndpoint
        baseArtifactId={baseArtifactId}
        branches={branches}
        compact
        descText={createMessage(GIT_CD_ENDPOINT_DESC)}
        isFetchBranchesLoading={isFetchBranchesLoading}
        selectedBranch={selectedBranch || "BRANCH"}
        setSelectedBranch={setSelectedBranch}
      />
    </div>
  );
}

export default CDExisting;
