export * from "ce/pages/Editor/IDE/EditorPane/JS/hooks";
import React, { useMemo } from "react";
import {
  useGroupedAddJsOperations as CE_useGroupedAddJsOperations,
  useJSEditorRoutes as CE_useJSEditorRoutes,
} from "ce/pages/Editor/IDE/EditorPane/JS/hooks";

import { FocusEntity } from "navigation/FocusEntity";
import { useModuleOptions } from "ee/utils/moduleInstanceHelpers";
import { groupBy } from "lodash";
import type { GroupedAddOperations } from "ee/pages/Editor/IDE/EditorPane/Query/hooks";
import type { UseRoutes } from "IDE/Interfaces/UseRoutes";
import {
  BUILDER_CUSTOM_PATH,
  BUILDER_PATH,
  BUILDER_PATH_DEPRECATED,
} from "ee/constants/routes/appRoutes";
import { MODULE_INSTANCE_ID_PATH } from "ee/constants/routes/appRoutes";
import { EDITOR_PANE_TEXTS, createMessage } from "ee/constants/messages";
import { lazy, Suspense } from "react";
import { retryPromise } from "utils/AppsmithUtils";
import Skeleton from "widgets/Skeleton";

/**
 * Updating to add JS module options in the list of Add JS Operations.
 * **/
export const useGroupedAddJsOperations = (): GroupedAddOperations => {
  const ce_jsOperations = CE_useGroupedAddJsOperations();
  const moduleCreationOptions = useModuleOptions();
  const jsModuleCreationOptions = moduleCreationOptions.filter(
    (opt) => opt.focusEntityType === FocusEntity.JS_MODULE_INSTANCE,
  );
  const packageJSModuleGroups = groupBy(
    jsModuleCreationOptions,
    "focusEntityType",
  );
  const jsOperations = [...ce_jsOperations];

  Object.entries(packageJSModuleGroups).forEach(([packageTitle, modules]) => {
    jsOperations.push({
      title: createMessage(EDITOR_PANE_TEXTS.js_create_modules),
      className: `t--${packageTitle}`,
      operations: modules,
    });
  });

  return jsOperations;
};
const ModuleInstanceEditor = lazy(async () =>
  retryPromise(
    async () =>
      import(
        /* webpackChunkName: "ModuleInstanceEditor" */ "ee/pages/Editor/ModuleInstanceEditor"
      ),
  ),
);

export const useJSEditorRoutes = (path: string): UseRoutes => {
  const ceRoutes = CE_useJSEditorRoutes(path);

  return useMemo(
    () => [
      {
        key: "ModuleInstanceEditor",
        component: (args) => (
          <Suspense fallback={<Skeleton />}>
            <ModuleInstanceEditor {...args} />
          </Suspense>
        ),
        exact: true,
        path: [
          BUILDER_PATH + MODULE_INSTANCE_ID_PATH,
          BUILDER_CUSTOM_PATH + MODULE_INSTANCE_ID_PATH,
          BUILDER_PATH_DEPRECATED + MODULE_INSTANCE_ID_PATH,
        ],
      },
      ...ceRoutes,
    ],
    [ceRoutes],
  );
};
