import Api from "api/Api";
import type { Package } from "ee/constants/PackageConstants";
import type { ApiResponse } from "api/ApiResponses";
import type { AxiosPromise } from "axios";
import type {
  Module,
  ModuleMetadata,
  PACKAGE_PULL_STATUS,
} from "ee/constants/ModuleConstants";
import type {
  DeletePackagePayload,
  PublishPackagePayload,
  FetchConsumablePackagesInWorkspacePayload,
  PullPackagePayload,
} from "ee/actions/packageActions";
import type { Datasource } from "entities/Datasource";
import type { ModuleInstance } from "ee/constants/ModuleInstanceConstants";
import type { FetchModuleInstanceEntitiesResponse } from "./ModuleInstanceApi";

export interface CreatePackagePayload {
  workspaceId: string;
  name: string;
  icon?: string;
  color?: string;
}

export interface FetchPackagePayload {
  basePackageId: string;
}

export interface FetchPackageResponse {
  packageData: Package;
  modules: Module[];
  modulesMetadata: ModuleMetadata[];
}

export interface FetchConsumablePackagesInWorkspaceResponse {
  packages: Package[];
  modules: Module[];
  moduleMetadata: ModuleMetadata[];
}

export interface PullPackageResponse {
  consumables: FetchConsumablePackagesInWorkspaceResponse;
  moduleInstances: ModuleInstance[];
  entities: FetchModuleInstanceEntitiesResponse;
}

export interface ImportPackagePayload {
  workspaceId: string;
  file?: File;
  packageId?: string;
}

export interface ImportPackageResponse {
  package: Package;
  isPartialImport: boolean;
  unConfiguredDatasourceList: Datasource[];
}

export interface FetchPackagesByWorkspacePayload {
  workspaceId: string;
}

export interface PublishPackageResponse {
  status: boolean;
  lastPublishedVersion: string;
}

export interface PollPackagePullStatusApiPayload {
  applicationId: string;
}

export type PollPackagePullStatusResponse = PACKAGE_PULL_STATUS;

const BASE_URL = "v1/packages";
const PACKAGE_PUBLISH_TIMEOUT = 60000; // in ms
const PACKAGE_PULL_TIMEOUT = 60000; // in ms

class PackageApi extends Api {
  static async fetchAllPackages() {
    const url = `${BASE_URL}`;

    return Api.get(url);
  }

  static async fetchPackagesByWorkspace(
    payload: FetchPackagesByWorkspacePayload,
  ): Promise<AxiosPromise<ApiResponse<Package[]>>> {
    const url = BASE_URL;

    return Api.get(url, payload);
  }

  static async fetchConsumablePackagesInWorkspace(
    payload: FetchConsumablePackagesInWorkspacePayload,
  ): Promise<
    AxiosPromise<ApiResponse<FetchConsumablePackagesInWorkspaceResponse>>
  > {
    const url = `${BASE_URL}/consumables?branchedApplicationId=${payload.applicationId}`;

    return Api.get(url);
  }

  static async createPackage(
    payload: CreatePackagePayload,
  ): Promise<AxiosPromise<ApiResponse<Package>>> {
    const url = BASE_URL;
    const { workspaceId, ...body } = payload;
    const queryParams = {
      workspaceId,
    };

    return Api.post(url, body, queryParams);
  }

  static async fetchPackage(
    payload: FetchPackagePayload,
  ): Promise<AxiosPromise<ApiResponse<FetchPackageResponse>>> {
    const { basePackageId } = payload;
    const url = `${BASE_URL}/${basePackageId}`;

    return Api.get(url);
  }

  static async pullPackage(
    payload: PullPackagePayload,
  ): Promise<AxiosPromise<ApiResponse<PullPackageResponse>>> {
    const { applicationId, pageId } = payload;
    const url = `${BASE_URL}/${applicationId}/pull`;

    const queryParams = {
      requestPageId: pageId,
    };

    return Api.post(url, null, queryParams, {
      timeout: PACKAGE_PULL_TIMEOUT,
    });
  }

  static async pollPackagePullStatus(
    payload: PollPackagePullStatusApiPayload,
  ): Promise<AxiosPromise<ApiResponse<PollPackagePullStatusResponse>>> {
    const { applicationId } = payload;
    const url = `${BASE_URL}/${applicationId}/poll-pull-status`;

    return Api.get(url);
  }

  static async updatePackage(
    payload: Package,
  ): Promise<AxiosPromise<ApiResponse<Package>>> {
    const url = `${BASE_URL}/${payload.id}`;

    return Api.put(url, payload);
  }

  static async deletePackage(
    payload: DeletePackagePayload,
  ): Promise<AxiosPromise<ApiResponse<Package>>> {
    const url = `${BASE_URL}/${payload.id}`;

    return Api.delete(url);
  }

  static async publishPackage(
    payload: PublishPackagePayload,
  ): Promise<AxiosPromise<ApiResponse<PublishPackageResponse>>> {
    const { packageId } = payload;
    const url = `${BASE_URL}/${packageId}/publish`;

    return Api.post(url, undefined, undefined, {
      timeout: PACKAGE_PUBLISH_TIMEOUT,
    });
  }

  static async importPackage(
    payload: ImportPackagePayload,
  ): Promise<AxiosPromise<ApiResponse<ImportPackageResponse>>> {
    const formData = new FormData();
    const { file, packageId, workspaceId } = payload;

    if (file) {
      formData.append("file", file);
    }

    const url = `${BASE_URL}/import/${workspaceId}`;
    const queryParams: Record<string, string> = {};

    if (packageId) {
      queryParams.packageId = packageId;
    }

    return Api.post(url, formData, queryParams, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default PackageApi;
