import { all, put, call, takeLatest } from "redux-saga/effects";

import {
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "ee/constants/ReduxActionConstants";
import PackageEditorEngine from "ee/entities/Engine/PackageEditorEngine";
import type { ReduxAction } from "actions/ReduxActionTypes";
import type { InitPackageEditorPayload } from "ee/actions/packageInitActions";
import { resetEditorRequest } from "actions/initActions";

export function* startPackageEngine(
  action: ReduxAction<InitPackageEditorPayload>,
) {
  try {
    const packageEngine = new PackageEditorEngine();

    /**
     * During editor switches like app (view mode) -> package
     * there are certain cases were stale data stays in reducers.
     * This ensures a clean state of reducers and avoids any dependency
     */
    yield put(resetEditorRequest());
    yield call(packageEngine.setupEngine);
    yield call(packageEngine.loadPackage, action.payload.basePackageId);
    yield call(packageEngine.loadPluginsAndDatasources);
    yield call(packageEngine.completeChore);
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.INITIALIZE_PACKAGE_EDITOR_ERROR,
      payload: {
        error,
      },
    });
  }
}

export default function* watchPackageInitSagas() {
  yield all([
    takeLatest(ReduxActionTypes.INITIALIZE_PACKAGE_EDITOR, startPackageEngine),
  ]);
}
