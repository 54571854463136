export * from "ce/reducers/uiReducers/editorReducer";
import type { RefactorModulePayload } from "ee/api/ModuleApi";
import { PACKAGE_PULL_STATUS } from "ee/constants/ModuleConstants";
import type { Module } from "ee/constants/ModuleConstants";
import type { ReduxAction } from "actions/ReduxActionTypes";
import {
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "ee/constants/ReduxActionConstants";
import {
  handlers as CE_handlers,
  initialState as CE_initialState,
} from "ce/reducers/uiReducers/editorReducer";
import type { EditorReduxState as CE_EditorReduxState } from "ce/reducers/uiReducers/editorReducer";
import { klona } from "klona";
import { createReducer } from "utils/ReducerUtils";
import type { DeleteModulePayload } from "ee/actions/moduleActions";
import type { SetupPageActionPayload } from "actions/pageActions";
import type { Package } from "ee/constants/PackageConstants";

export type EditorReduxState = CE_EditorReduxState & {
  isPackageEditorInitialized: boolean;
  currentModuleId?: string | null;
  currentBaseModuleId?: string | null;
  currentPackageId?: string;
  currentBasePackageId?: string;
  isPackagePublishing: boolean;
  isModuleFetchingEntities: boolean;
  currentWorkflowId?: string;
  isWorkflowPublishing: boolean;
  isWorkflowTokenGenerating: boolean;
  isWorkflowScheduleSaving: boolean;
  isWorkflowEditorInitialized: boolean;
  isModuleUpdating: boolean;
  hasModuleUpdateError: boolean;
  currentProcessingModuleReferences: Set<string>;
  isSettingUpModule: boolean;
  isFetchingConsumableModules: boolean;
  moduleReferences: Record<string, Set<string>>;
  isModuleReferenceUpdating: boolean;
  hasCyclicModuleReference: boolean;
  packagePullStatus?: PACKAGE_PULL_STATUS;
};

export const initialState: EditorReduxState = {
  ...CE_initialState,
  isPackageEditorInitialized: false,
  isPackagePublishing: false,
  isWorkflowPublishing: false,
  isModuleFetchingEntities: false,
  isWorkflowEditorInitialized: false,
  isWorkflowTokenGenerating: false,
  isWorkflowScheduleSaving: false,
  isModuleUpdating: false,
  hasModuleUpdateError: false,
  currentProcessingModuleReferences: new Set(),
  isSettingUpModule: false,
  isFetchingConsumableModules: false,
  moduleReferences: {},
  isModuleReferenceUpdating: false,
  hasCyclicModuleReference: false,
};

const setIsModuleUpdating = (state: EditorReduxState) => ({
  ...state,
  hasModuleUpdateError: false,
  isModuleUpdating: true,
});
const unsetIsModuleUpdating = (state: EditorReduxState) => ({
  ...state,
  hasModuleUpdateError: false,
  isModuleUpdating: false,
});

const setIsModuleUpdatingWithError = (state: EditorReduxState) => ({
  ...state,
  hasModuleUpdateError: true,
  isModuleUpdating: false,
});

const handlers = {
  ...CE_handlers,
  [ReduxActionTypes.INITIALIZE_PACKAGE_EDITOR_SUCCESS]: (
    state: EditorReduxState,
  ) => {
    return {
      ...state,
      isPackageEditorInitialized: true,
    };
  },
  [ReduxActionTypes.INITIALIZE_PACKAGE_EDITOR]: (state: EditorReduxState) => {
    return {
      ...state,
      isPackageEditorInitialized: false,
      moduleReferences: {},
    };
  },

  [ReduxActionTypes.SET_CURRENT_PACKAGE_ID]: (
    state: EditorReduxState,
    action: ReduxAction<Package>,
  ) => {
    return {
      ...state,
      currentPackageId: action.payload?.id,
      currentBasePackageId: action.payload?.baseId,
    };
  },

  [ReduxActionTypes.FETCH_MODULE_ENTITIES_INIT]: (state: EditorReduxState) => {
    return {
      ...state,
      isModuleFetchingEntities: true,
    };
  },

  [ReduxActionTypes.FETCH_MODULE_ENTITIES_SUCCESS]: (
    state: EditorReduxState,
  ) => {
    return {
      ...state,
      isModuleFetchingEntities: false,
    };
  },

  [ReduxActionErrorTypes.FETCH_MODULE_ENTITIES_ERROR]: (
    state: EditorReduxState,
  ) => {
    return {
      ...state,
      isModuleFetchingEntities: false,
    };
  },

  [ReduxActionTypes.SET_HAS_CYCLIC_MODULE_REFERENCE]: (
    state: EditorReduxState,
    action: ReduxAction<{ hasCyclicModuleReference: boolean }>,
  ) => {
    return {
      ...state,
      hasCyclicModuleReference: action.payload.hasCyclicModuleReference,
    };
  },

  [ReduxActionTypes.SET_CURRENT_WORKFLOW_ID]: (
    state: EditorReduxState,
    action: ReduxAction<{ workflowId: string }>,
  ) => {
    return {
      ...state,
      currentWorkflowId: action.payload.workflowId,
    };
  },

  [ReduxActionTypes.INITIALIZE_WORKFLOW_EDITOR_SUCCESS]: (
    state: EditorReduxState,
  ) => {
    return {
      ...state,
      isWorkflowEditorInitialized: true,
    };
  },
  [ReduxActionTypes.INITIALIZE_WORKFLOW_EDITOR]: (state: EditorReduxState) => {
    return {
      ...state,
      isWorkflowEditorInitialized: false,
    };
  },
  [ReduxActionTypes.UPDATE_MODULE_INPUTS_INIT]: setIsModuleUpdating,
  [ReduxActionTypes.UPDATE_MODULE_INPUTS_SUCCESS]: unsetIsModuleUpdating,
  [ReduxActionErrorTypes.UPDATE_MODULE_INPUTS_ERROR]:
    setIsModuleUpdatingWithError,

  [ReduxActionTypes.SAVE_MODULE_NAME_INIT]: setIsModuleUpdating,
  [ReduxActionTypes.SAVE_MODULE_NAME_SUCCESS]: unsetIsModuleUpdating,
  [ReduxActionErrorTypes.SAVE_MODULE_NAME_ERROR]: setIsModuleUpdatingWithError,

  [ReduxActionTypes.DELETE_MODULE_INIT]: setIsModuleUpdating,
  [ReduxActionTypes.DELETE_MODULE_SUCCESS]: unsetIsModuleUpdating,
  [ReduxActionErrorTypes.DELETE_MODULE_ERROR]: setIsModuleUpdatingWithError,

  [ReduxActionTypes.CREATE_QUERY_MODULE_INIT]: setIsModuleUpdating,
  [ReduxActionErrorTypes.CREATE_QUERY_MODULE_ERROR]:
    setIsModuleUpdatingWithError,

  [ReduxActionTypes.CREATE_JS_MODULE_INIT]: setIsModuleUpdating,
  [ReduxActionErrorTypes.CREATE_JS_MODULE_ERROR]: setIsModuleUpdatingWithError,

  [ReduxActionTypes.UPDATE_MODULE_INPUTS_INIT]: setIsModuleUpdating,
  [ReduxActionTypes.UPDATE_MODULE_INPUTS_SUCCESS]: unsetIsModuleUpdating,
  [ReduxActionErrorTypes.UPDATE_MODULE_INPUTS_ERROR]:
    setIsModuleUpdatingWithError,

  [ReduxActionTypes.REFACTOR_JS_ACTION_NAME]: setIsModuleUpdating,
  [ReduxActionTypes.REFACTOR_JS_ACTION_NAME_SUCCESS]: unsetIsModuleUpdating,
  [ReduxActionErrorTypes.REFACTOR_JS_ACTION_NAME_ERROR]:
    setIsModuleUpdatingWithError,

  [ReduxActionTypes.UPDATE_JS_ACTION_BODY_INIT]: setIsModuleUpdating,
  [ReduxActionTypes.UPDATE_JS_ACTION_BODY_SUCCESS]: unsetIsModuleUpdating,
  [ReduxActionErrorTypes.UPDATE_JS_ACTION_BODY_ERROR]:
    setIsModuleUpdatingWithError,

  [ReduxActionTypes.UPDATE_ACTION_INIT]: setIsModuleUpdating,
  [ReduxActionTypes.UPDATE_ACTION_SUCCESS]: unsetIsModuleUpdating,
  [ReduxActionErrorTypes.UPDATE_ACTION_ERROR]: setIsModuleUpdatingWithError,

  [ReduxActionTypes.DELETE_MODULE_SUCCESS]: (
    state: EditorReduxState,
    action: ReduxAction<DeleteModulePayload>,
  ) => {
    const updatedState = unsetIsModuleUpdating(state);
    const current = klona(state.moduleReferences);

    delete current[action.payload.id];

    return {
      ...updatedState,
      moduleReferences: current,
    };
  },

  [ReduxActionTypes.SETUP_PAGE_INIT]: (
    state: EditorReduxState,
    action: ReduxAction<SetupPageActionPayload>,
  ) => {
    const { packagePullStatus } = action.payload;
    const status = packagePullStatus?.data || PACKAGE_PULL_STATUS.UPGRADED;

    return {
      ...state,
      packagePullStatus: status,
    };
  },

  [ReduxActionTypes.PULL_PACKAGE_SUCCESS]: (state: EditorReduxState) => {
    return {
      ...state,
      packagePullStatus: PACKAGE_PULL_STATUS.UPGRADED,
    };
  },

  [ReduxActionTypes.PULL_PACKAGE_INIT]: (state: EditorReduxState) => {
    return {
      ...state,
      packagePullStatus: PACKAGE_PULL_STATUS.UPGRADING,
    };
  },

  [ReduxActionErrorTypes.PULL_PACKAGE_ERROR]: (state: EditorReduxState) => {
    return {
      ...state,
      packagePullStatus: PACKAGE_PULL_STATUS.UPGRADABLE,
    };
  },

  [ReduxActionTypes.POLL_PACKAGE_PULL_STATUS_SUCCESS]: (
    state: EditorReduxState,
  ) => {
    return {
      ...state,
      packagePullStatus: PACKAGE_PULL_STATUS.UPGRADED,
    };
  },

  [ReduxActionErrorTypes.POLL_PACKAGE_PULL_STATUS_ERROR]: (
    state: EditorReduxState,
  ) => {
    return {
      ...state,
      packagePullStatus: PACKAGE_PULL_STATUS.UPGRADABLE,
    };
  },

  [ReduxActionTypes.MODIFY_MODULE_REFERENCE_BY_NAME_INIT]: (
    state: EditorReduxState,
  ) => {
    return {
      ...state,
      hasModuleUpdateError: false,
      isModuleReferenceUpdating: true,
    };
  },
  [ReduxActionTypes.MODIFY_MODULE_REFERENCE_BY_NAME_SUCCESS]: (
    state: EditorReduxState,
  ) => {
    return {
      ...state,
      isModuleReferenceUpdating: false,
    };
  },
  [ReduxActionErrorTypes.MODIFY_MODULE_REFERENCE_BY_NAME_ERROR]: (
    state: EditorReduxState,
  ) => {
    return {
      ...state,
      isModuleReferenceUpdating: false,
      hasModuleUpdateError: true,
    };
  },

  [ReduxActionTypes.UPDATE_MODULE_INSTANCE_INIT]: (state: EditorReduxState) => {
    return {
      ...state,
      loadingStates: {
        ...state.loadingStates,
        savingEntity: true,
      },
    };
  },

  [ReduxActionTypes.UPDATE_MODULE_INIT]: (state: EditorReduxState) => {
    return {
      ...state,
      isModuleUpdating: true,
    };
  },

  [ReduxActionTypes.UPDATE_MODULE_SUCCESS]: (state: EditorReduxState) => {
    return {
      ...state,
      isModuleUpdating: false,
    };
  },

  [ReduxActionErrorTypes.UPDATE_MODULE_ERROR]: (state: EditorReduxState) => {
    return {
      ...state,
      isModuleUpdating: false,
    };
  },

  [ReduxActionTypes.UPDATE_MODULE_INSTANCE_SUCCESS]: (
    state: EditorReduxState,
  ) => {
    return {
      ...state,
      loadingStates: {
        ...state.loadingStates,
        savingEntity: false,
      },
    };
  },

  [ReduxActionErrorTypes.UPDATE_MODULE_INSTANCE_ERROR]: (
    state: EditorReduxState,
  ) => {
    return {
      ...state,
      loadingStates: {
        ...state.loadingStates,
        savingEntity: false,
      },
    };
  },

  [ReduxActionTypes.SET_CURRENT_MODULE]: (
    state: EditorReduxState,
    action: ReduxAction<Module>,
  ) => {
    return {
      ...state,
      currentModuleId: action.payload?.id,
      currentBaseModuleId: action.payload?.baseId,
    };
  },

  [ReduxActionTypes.CREATE_QUERY_MODULE_SUCCESS]: (
    state: EditorReduxState,
    action: ReduxAction<Module>,
  ) => {
    const current = klona(state.moduleReferences);

    current[action.payload.id] = new Set();

    return {
      ...state,
      moduleReferences: current,
      hasModuleUpdateError: false,
      isModuleUpdating: false,
    };
  },

  [ReduxActionTypes.CREATE_JS_MODULE_SUCCESS]: (
    state: EditorReduxState,
    action: ReduxAction<Module>,
  ) => {
    const current = klona(state.moduleReferences);

    current[action.payload.id] = new Set();

    return {
      ...state,
      moduleReferences: current,
      hasModuleUpdateError: false,
      isModuleUpdating: false,
    };
  },

  [ReduxActionTypes.REFACTOR_MODULE_REFERENCES_UPDATE]: (
    state: EditorReduxState,
    action: ReduxAction<RefactorModulePayload>,
  ) => {
    const current = klona(state.moduleReferences);
    const { newName, oldName } = action.payload;

    Object.values(current).forEach((references) => {
      if (references.has(oldName)) {
        references.delete(oldName);
        references.add(newName);
      }
    });

    return {
      ...state,
      moduleReferences: current,
    };
  },

  [ReduxActionTypes.SET_MODULE_REFERENCES]: (
    state: EditorReduxState,
    action: ReduxAction<{ moduleId: string; references: Set<string> }>,
  ) => {
    const { moduleId, references } = action.payload;
    const current = klona(state.moduleReferences);

    current[moduleId] = references;

    return {
      ...state,
      moduleReferences: current,
    };
  },

  [ReduxActionTypes.INIT_MODULE_REFERENCES]: (
    state: EditorReduxState,
    action: ReduxAction<{ references: Record<string, Set<string>> }>,
  ) => {
    return {
      ...state,
      moduleReferences: action.payload.references,
    };
  },

  [ReduxActionTypes.PUBLISH_PACKAGE_INIT]: (state: EditorReduxState) => {
    return {
      ...state,
      isPackagePublishing: true,
    };
  },

  [ReduxActionTypes.PUBLISH_PACKAGE_SUCCESS]: (state: EditorReduxState) => {
    return {
      ...state,
      isPackagePublishing: false,
    };
  },

  [ReduxActionErrorTypes.PUBLISH_PACKAGE_ERROR]: (state: EditorReduxState) => {
    return {
      ...state,
      isPackagePublishing: false,
    };
  },

  [ReduxActionTypes.PUBLISH_WORKFLOW_INIT]: (state: EditorReduxState) => {
    return {
      ...state,
      isWorkflowPublishing: true,
    };
  },

  [ReduxActionTypes.PUBLISH_WORKFLOW_SUCCESS]: (state: EditorReduxState) => {
    return {
      ...state,
      isWorkflowPublishing: false,
    };
  },

  [ReduxActionErrorTypes.PUBLISH_WORKFLOW_ERROR]: (state: EditorReduxState) => {
    return {
      ...state,
      isWorkflowPublishing: false,
    };
  },
  [ReduxActionTypes.TOGGLE_WORKFLOW_TOKEN]: (state: EditorReduxState) => {
    return {
      ...state,
      isWorkflowTokenGenerating: true,
    };
  },
  [ReduxActionTypes.CREATE_WORKFLOW_TOKEN_SUCCESS]: (
    state: EditorReduxState,
  ) => {
    return {
      ...state,
      isWorkflowTokenGenerating: false,
    };
  },
  [ReduxActionTypes.DELETE_WORKFLOW_TOKEN_SUCCESS]: (
    state: EditorReduxState,
  ) => {
    return {
      ...state,
      isWorkflowTokenGenerating: false,
    };
  },
  [ReduxActionErrorTypes.TOGGLE_WORKFLOW_TOKEN_ERROR]: (
    state: EditorReduxState,
  ) => {
    return {
      ...state,
      isWorkflowTokenGenerating: false,
    };
  },
  [ReduxActionTypes.WORKFLOW_SAVE_SCHEDULE]: (state: EditorReduxState) => {
    return {
      ...state,
      isWorkflowScheduleSaving: true,
    };
  },
  [ReduxActionTypes.WORKFLOW_TOGGLE_SCHEDULE]: (state: EditorReduxState) => {
    return {
      ...state,
      isWorkflowScheduleSaving: true,
    };
  },
  [ReduxActionErrorTypes.TOGGLE_WORKFLOW_SCHEDULE_ERROR]: (
    state: EditorReduxState,
  ) => {
    return {
      ...state,
      isWorkflowScheduleSaving: false,
    };
  },
  [ReduxActionErrorTypes.SAVE_WORKFLOW_SCHEDULE_ERROR]: (
    state: EditorReduxState,
  ) => {
    return {
      ...state,
      isWorkflowScheduleSaving: false,
    };
  },
  [ReduxActionTypes.FETCH_WORKFLOW_SUCCESS]: (state: EditorReduxState) => {
    return {
      ...state,
      isWorkflowScheduleSaving: false,
    };
  },
  [ReduxActionTypes.SET_CURR_PROCESSING_MODULE_REFERENCE]: (
    state: EditorReduxState,
    action: ReduxAction<{ names: string[] }>,
  ) => {
    const currentProcessingModuleReferences = new Set([
      ...state.currentProcessingModuleReferences,
      ...action.payload.names,
    ]);

    return {
      ...state,
      currentProcessingModuleReferences,
    };
  },
  [ReduxActionTypes.UNSET_CURR_PROCESSING_MODULE_REFERENCE]: (
    state: EditorReduxState,
    action: ReduxAction<{ names: string[] }>,
  ) => {
    const currentProcessingModuleReferences = new Set([
      ...state.currentProcessingModuleReferences,
    ]);

    action.payload.names.forEach((name) => {
      currentProcessingModuleReferences.delete(name);
    });

    return {
      ...state,
      currentProcessingModuleReferences,
    };
  },
  [ReduxActionTypes.FETCH_CONSUMABLE_PACKAGES_IN_WORKSPACE_INIT]: (
    state: EditorReduxState,
  ) => {
    return {
      ...state,
      isFetchingConsumableModules: true,
    };
  },
  [ReduxActionTypes.FETCH_CONSUMABLE_PACKAGES_IN_WORKSPACE_SUCCESS]: (
    state: EditorReduxState,
  ) => {
    return {
      ...state,
      isFetchingConsumableModules: false,
    };
  },
  [ReduxActionErrorTypes.FETCH_CONSUMABLE_PACKAGES_IN_WORKSPACE_ERROR]: (
    state: EditorReduxState,
  ) => {
    return {
      ...state,
      isFetchingConsumableModules: false,
    };
  },

  [ReduxActionTypes.SETUP_MODULE_INIT]: (state: EditorReduxState) => {
    return {
      ...state,
      isSettingUpModule: true,
    };
  },
  [ReduxActionTypes.SETUP_MODULE_SUCCESS]: (state: EditorReduxState) => {
    return {
      ...state,
      isSettingUpModule: false,
    };
  },
  [ReduxActionErrorTypes.SETUP_MODULE_ERROR]: (state: EditorReduxState) => {
    return {
      ...state,
      isSettingUpModule: false,
    };
  },

  [ReduxActionTypes.UPDATE_SELECTED_RAG_DOCUMENTS_INIT]: (
    state: EditorReduxState,
  ) => {
    return {
      ...state,
      loadingStates: {
        ...state.loadingStates,
        savingEntity: true,
      },
    };
  },

  [ReduxActionTypes.UPDATE_SELECTED_RAG_DOCUMENTS_SUCCESS]: (
    state: EditorReduxState,
  ) => {
    return {
      ...state,
      loadingStates: {
        ...state.loadingStates,
        savingEntity: false,
      },
    };
  },
};

export default createReducer(initialState, handlers);
