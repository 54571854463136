export * from "ce/pages/Editor/IDE/EditorPane/Query/utils/getQueryUrl";

import { getQueryUrl as CE_getQueryAddUrl } from "ce/pages/Editor/IDE/EditorPane/Query/utils/getQueryUrl";
import { moduleInstanceEditorURL } from "ee/RouteBuilder";
import { MODULE_TYPE } from "ee/constants/ModuleConstants";
import type { FocusEntityInfo } from "navigation/FocusEntity";

export const getQueryUrl = (
  item: FocusEntityInfo,
  add: boolean = true,
): string => {
  if (item.params.moduleInstanceId) {
    return moduleInstanceEditorURL({
      basePageId: item.params.basePageId,
      moduleType: MODULE_TYPE.QUERY,
      moduleInstanceId: item.params.moduleInstanceId,
      add,
    });
  }

  return CE_getQueryAddUrl(item, add);
};
