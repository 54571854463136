export * from "ce/pages/Editor/IDE/EditorPane/Query/hooks";

import {
  useGroupedAddQueryOperations as CE_useGroupedAddQueryOperations,
  useQueryEditorRoutes as CE_useQueryEditorRoutes,
  useAddQueryListItems,
} from "ce/pages/Editor/IDE/EditorPane/Query/hooks";
import type { GroupedAddOperations } from "ce/pages/Editor/IDE/EditorPane/Query/hooks";

import { FocusEntity } from "navigation/FocusEntity";
import { groupBy } from "lodash";
import { useModuleOptions } from "ee/utils/moduleInstanceHelpers";
import {
  BUILDER_CUSTOM_PATH,
  BUILDER_PATH,
  BUILDER_PATH_DEPRECATED,
} from "ee/constants/routes/appRoutes";
import { MODULE_INSTANCE_ID_PATH } from "ee/constants/routes/appRoutes";
import type { UseRoutes } from "IDE/Interfaces/UseRoutes";
import { EDITOR_PANE_TEXTS, createMessage } from "ee/constants/messages";
import { lazy } from "react";
import { retryPromise } from "utils/AppsmithUtils";
import type {
  EntityGroupProps,
  EntityItemProps,
  ListItemProps,
} from "@appsmith/ads";

export const useGroupedAddQueryOperations = () => {
  const [fromSourceGroup, fromBlankGroup] = CE_useGroupedAddQueryOperations();
  const { getListItems } = useAddQueryListItems();
  const moduleOperations = useModuleOptions();
  const queryModules = moduleOperations.filter(
    (fileOperations) =>
      fileOperations.focusEntityType === FocusEntity.QUERY_MODULE_INSTANCE,
  );
  const packageQueryModuleGroups = groupBy(queryModules, "focusEntityType");
  const queryModulesGroups: GroupedAddOperations = [];

  Object.entries(packageQueryModuleGroups).forEach(
    ([packageTitle, instanceOps]) => {
      queryModulesGroups.push({
        title: createMessage(EDITOR_PANE_TEXTS.queries_create_modules),
        className: `t--${packageTitle}`,
        operations: instanceOps,
      });
    },
  );

  const queryModulesGroupedItems: EntityGroupProps<
    ListItemProps | EntityItemProps
  >[] = [];

  queryModulesGroups.map((group) => {
    const items = getListItems(group.operations);

    queryModulesGroupedItems.push({
      groupTitle: group.title || "",
      className: group.className,
      items,
    });
  });

  // We still show the existing datasource group first, then add the module groups and finally the blanks group
  return [fromSourceGroup, ...queryModulesGroupedItems, fromBlankGroup];
};

export const useQueryEditorRoutes = (path: string): UseRoutes => {
  const ceRoutes = CE_useQueryEditorRoutes(path);

  return [
    {
      key: "ModuleInstanceEditor",
      component: lazy(async () =>
        retryPromise(
          async () =>
            import(
              /* webpackChunkName: "ModuleInstanceEditor" */ "ee/pages/Editor/ModuleInstanceEditor"
            ),
        ),
      ),
      exact: true,
      path: [
        BUILDER_PATH + MODULE_INSTANCE_ID_PATH,
        BUILDER_CUSTOM_PATH + MODULE_INSTANCE_ID_PATH,
        BUILDER_PATH_DEPRECATED + MODULE_INSTANCE_ID_PATH,
      ],
    },
    ...ceRoutes,
  ];
};
