import type { GitArtifactPayloadAction } from "git/store/types";
import type { ToggleCDResponse } from "../requests/toggleCDRequest";
import { call, put, select } from "redux-saga/effects";
import toggleCDRequest from "../requests/toggleCDRequest";
import { validateResponse } from "sagas/ErrorSagas";
import { gitArtifactActions } from "git/store/gitArtifactSlice";
import log from "loglevel";
import { captureException } from "@sentry/react";
import { selectGitApiContractsEnabled } from "git/store/selectors/gitFeatureFlagSelectors";

export function* toggleCDSaga(action: GitArtifactPayloadAction) {
  const { artifactDef } = action.payload;
  let response: ToggleCDResponse | undefined;

  try {
    const isGitApiContractsEnabled: boolean = yield select(
      selectGitApiContractsEnabled,
    );

    response = yield call(
      toggleCDRequest,
      artifactDef.artifactType,
      artifactDef.baseArtifactId,
      isGitApiContractsEnabled,
    );
    const isValidResponse: boolean = yield validateResponse(response);

    if (response && isValidResponse) {
      yield put(gitArtifactActions.toggleCDSuccess({ artifactDef }));
      yield put(gitArtifactActions.fetchMetadataInit({ artifactDef }));
    }
  } catch (e) {
    if (response && response.responseMeta.error) {
      const { error } = response.responseMeta;

      yield put(gitArtifactActions.toggleCDError({ artifactDef, error }));
    } else {
      log.error(e);
      captureException(e);
    }
  }
}
