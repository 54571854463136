import type { PACKAGE_PULL_STATUS } from "ee/constants/ModuleConstants";
import type { Package } from "ee/constants/PackageConstants";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";

export interface CreatePackageFromWorkspacePayload {
  workspaceId: string;
}

export interface FetchPackagePayload {
  basePackageId: string;
}

export interface DeletePackagePayload {
  id: string;
}

export interface PublishPackagePayload {
  packageId: string;
}

export interface FetchConsumablePackagesInWorkspacePayload {
  workspaceId: string;
  applicationId: string;
}

export interface PullPackagePayload {
  applicationId: string;
  pageId: string;
}

export interface RequestPullPackagePayload {
  packagePullStatus?: PACKAGE_PULL_STATUS;
  pageId: string;
}

export interface ImportPackagePayload {
  packageId?: string;
  workspaceId: string;
  file?: File;
}

export interface PollPackagePullStatusPayload {
  applicationId: string;
  pageId: string;
  // For testing purposes
  maxPollCount?: number;
}

export const fetchPackagesForWorkspace = (workspaceId: string) => {
  return {
    type: ReduxActionTypes.FETCH_PACKAGES_FOR_WORKSPACE_INIT,
    payload: {
      workspaceId,
    },
  };
};

export const fetchConsumablePackagesInWorkspace = (
  payload: FetchConsumablePackagesInWorkspacePayload,
) => {
  return {
    type: ReduxActionTypes.FETCH_CONSUMABLE_PACKAGES_IN_WORKSPACE_INIT,
    payload,
  };
};

export const fetchPackage = (payload: FetchPackagePayload) => ({
  type: ReduxActionTypes.FETCH_PACKAGE_INIT,
  payload,
});

export const createPackageFromWorkspace = (
  payload: CreatePackageFromWorkspacePayload,
) => {
  return {
    type: ReduxActionTypes.CREATE_PACKAGE_FROM_WORKSPACE_INIT,
    payload,
  };
};

export const updatePackage = (
  payload: Partial<Package> & Pick<Package, "id">,
) => {
  return {
    type: ReduxActionTypes.UPDATE_PACKAGE_INIT,
    payload,
  };
};

export const deletePackage = (payload: DeletePackagePayload) => {
  return {
    type: ReduxActionTypes.DELETE_PACKAGE_INIT,
    payload,
  };
};

export const publishPackage = (payload: PublishPackagePayload) => {
  return {
    type: ReduxActionTypes.PUBLISH_PACKAGE_INIT,
    payload,
  };
};

export const importPackage = (payload: ImportPackagePayload) => {
  return {
    type: ReduxActionTypes.IMPORT_PACKAGE_INIT,
    payload,
  };
};

export const fetchJSLibrariesForPkg = () => {
  return {
    type: ReduxActionTypes.FETCH_JS_LIBRARIES_FOR_PKG_INIT,
    payload: null,
  };
};

export const pullPackage = (payload: PullPackagePayload) => {
  return {
    type: ReduxActionTypes.PULL_PACKAGE_INIT,
    payload,
  };
};

export const requestPullPackage = (payload: RequestPullPackagePayload) => {
  return {
    type: ReduxActionTypes.REQUEST_PULL_PACKAGE_INIT,
    payload,
  };
};

export const pollPackagePullStatus = (
  payload: PollPackagePullStatusPayload,
) => {
  return {
    type: ReduxActionTypes.POLL_PACKAGE_PULL_STATUS_INIT,
    payload,
  };
};
