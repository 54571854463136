import { Carbon } from "carbon-typescript-sdk";
import { RagConstants } from "../../../constants/ragConstants";
import type { RagAccessTokenRequestDto } from "../../../types/rag.dto";
import { validateApiPath } from "@appsmith/utils";

export const DEFAULT_CARBON_API_BASE_PATH = "https://carbon.appsmith.com";

export class CarbonClient {
  public static getClient(
    ragAccessTokenRequest: RagAccessTokenRequestDto,
  ): Carbon {
    const carbonApiKey: string = process.env.APPSMITH_CARBON_API_KEY || "";
    const carbonApiBasePath: string | undefined =
      process.env.APPSMITH_CARBON_API_BASE_PATH || DEFAULT_CARBON_API_BASE_PATH;

    return new Carbon({
      basePath: validateApiPath(carbonApiBasePath),
      apiKey: carbonApiKey,
      customerId: this.generateCustomerId(ragAccessTokenRequest),
    });
  }

  private static generateCustomerId(
    ragAccessTokenRequest: RagAccessTokenRequestDto,
  ): string {
    const { datasourceId, instanceId, tenantId, workspaceId } =
      ragAccessTokenRequest;
    const instanceInfo: string =
      RagConstants.INSTANCE_ID + RagConstants.COLON + instanceId;
    const tenantInfo: string =
      RagConstants.TENANT_ID + RagConstants.COLON + tenantId;
    const workspaceInfo: string =
      RagConstants.WORKSPACE_ID + RagConstants.COLON + workspaceId;
    const datasourceInfo: string =
      RagConstants.DATASOURCE_ID + RagConstants.COLON + datasourceId;

    return (
      instanceInfo +
      RagConstants.COMMA +
      tenantInfo +
      RagConstants.COMMA +
      workspaceInfo +
      RagConstants.COMMA +
      datasourceInfo
    );
  }
}
