import React from "react";
import CDInitialize from "./CDInitialize";
import CDExisting from "./CDExisting";
import noop from "lodash/noop";
import type { GitSettingsTab } from "git/constants/enums";
import type { GitBranch } from "git/types";

export interface CDLicensedViewProps {
  artifactLastDeployedAt: string | null;
  baseArtifactId: string | null;
  branches: GitBranch[] | null;
  cdApiKey: string | null;
  defaultBranch: string | null;
  generateCDApiKey: () => void;
  isCDDisableModalOpen: boolean;
  isCDEnabled: boolean;
  isCDReconfigureModalOpen: boolean;
  isFetchBranchesLoading: boolean;
  isGenerateCDApiKeyLoading: boolean;
  isToggleCDLoading: boolean;
  resetGenerateCDApiKey: () => void;
  toggleCD: () => void;
  toggleCDDisableModal: (open: boolean) => void;
  toggleCDReconfigureModal: (open: boolean) => void;
  toggleSettingsModal: (
    open: boolean,
    tab?: keyof typeof GitSettingsTab,
  ) => void;
}

function CDLicensedView({
  artifactLastDeployedAt = null,
  baseArtifactId = null,
  branches = null,
  cdApiKey = null,
  defaultBranch = null,
  generateCDApiKey = noop,
  isCDEnabled = false,
  isFetchBranchesLoading = false,
  isGenerateCDApiKeyLoading = false,
  resetGenerateCDApiKey = noop,
  toggleCD = noop,
  toggleCDDisableModal = noop,
  toggleCDReconfigureModal = noop,
  toggleSettingsModal = noop,
}: CDLicensedViewProps) {
  if (isCDEnabled) {
    return (
      <CDExisting
        artifactLastDeployedAt={artifactLastDeployedAt}
        baseArtifactId={baseArtifactId}
        branches={branches}
        cdApiKey={cdApiKey}
        defaultBranch={defaultBranch}
        isFetchBranchesLoading={isFetchBranchesLoading}
        isGenerateCDApiKeyLoading={isGenerateCDApiKeyLoading}
        resetGenerateCDApiKey={resetGenerateCDApiKey}
        toggleCDDisableModal={toggleCDDisableModal}
        toggleCDReconfigureModal={toggleCDReconfigureModal}
        toggleSettingsModal={toggleSettingsModal}
      />
    );
  }

  return (
    <CDInitialize
      baseArtifactId={baseArtifactId}
      branches={branches}
      cdApiKey={cdApiKey}
      defaultBranch={defaultBranch}
      generateCDApiKey={generateCDApiKey}
      isFetchBranchesLoading={isFetchBranchesLoading}
      isGenerateCDApiKeyLoading={isGenerateCDApiKeyLoading}
      resetGenerateCDApiKey={resetGenerateCDApiKey}
      toggleCD={toggleCD}
    />
  );
}

export default CDLicensedView;
