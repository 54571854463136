import { call, delay, put, select } from "redux-saga/effects";
import type {
  UpdateDefaultBranchRequestParams,
  UpdateDefaultBranchResponse,
} from "../requests/updateDefaultBranchRequest";
import updateDefaultBranchRequest from "../requests/updateDefaultBranchRequest";
import { validateResponse } from "sagas/ErrorSagas";
import { gitArtifactActions } from "git/store/gitArtifactSlice";
import type { UpdateDefaultBranchInitPayload } from "../store/actions/updateDefaultBranchActions";
import log from "loglevel";
import { captureException } from "@sentry/react";
import type { GitArtifactPayloadAction } from "git/store/types";
import { selectGitApiContractsEnabled } from "git/store/selectors/gitFeatureFlagSelectors";
import { toast } from "@appsmith/ads";
import {
  createMessage,
  UPDATE_DEFAULT_BRANCH_SUCCESS,
} from "ee/constants/messages";

export default function* updateDefaultBranchSaga(
  action: GitArtifactPayloadAction<UpdateDefaultBranchInitPayload>,
) {
  const { artifactDef, branchName } = action.payload;
  let response: UpdateDefaultBranchResponse | undefined;

  try {
    const params: UpdateDefaultBranchRequestParams = { branchName };

    const isGitApiContractsEnabled: boolean = yield select(
      selectGitApiContractsEnabled,
    );

    response = yield call(
      updateDefaultBranchRequest,
      artifactDef.artifactType,
      artifactDef.baseArtifactId,
      params,
      isGitApiContractsEnabled,
    );
    const isValidResponse: boolean = yield validateResponse(response);

    if (response && isValidResponse) {
      yield put(gitArtifactActions.updateDefaultBranchSuccess({ artifactDef }));
      toast.show(createMessage(UPDATE_DEFAULT_BRANCH_SUCCESS, branchName), {
        kind: "success",
      });
      // adding this for toast animation
      yield delay(500);
      location.reload();
    }
  } catch (e) {
    if (response && response.responseMeta.error) {
      const { error } = response.responseMeta;

      yield put(
        gitArtifactActions.updateDefaultBranchError({ artifactDef, error }),
      );
    } else {
      log.error(e);
      captureException(e);
    }
  }
}
