import React from "react";
import DefaultBranchView from "git/ce/components/DefaultBranch/DefaultBranchView";
import useDefaultBranch from "git/ee/hooks/useDefaultBranch";
import useGitFeatureFlags from "git/hooks/useGitFeatureFlags";
import useBranches from "git/hooks/useBranches";

export default function DefaultBranch() {
  const { branches } = useBranches();
  const { updateDefaultBranch } = useDefaultBranch();
  const { license_git_branch_protection_enabled } = useGitFeatureFlags();

  return (
    <DefaultBranchView
      branches={branches}
      isGitProtectedFeatureLicensed={license_git_branch_protection_enabled}
      updateDefaultBranch={updateDefaultBranch}
    />
  );
}
