import { IDE_TYPE } from "ee/IDE/Interfaces/IDETypes";

export * from "ce/utils/workflowHelpers";
import {
  CreateNewActionKey,
  type ActionParentEntityTypeInterface,
  type CreateNewActionKeyInterface,
} from "ee/entities/Engine/actionHelpers";
import { getWorkflowsList } from "ee/selectors/workflowSelectors";
import {
  SEARCH_ITEM_TYPES,
  type ActionOperation,
} from "components/editorComponents/GlobalSearch/utils";
import { FocusEntity } from "navigation/FocusEntity";
import { useSelector } from "react-redux";
import type { EventLocation } from "./analyticsUtilTypes";
import { WorkflowIcon } from "pages/Editor/Explorer/ExplorerIcons";
import { createWorkflowExecutionActionBasedOnParentEntityType } from "ee/actions/helpers";
import { PluginPackageName } from "entities/Plugin";
import { getPluginIdOfPackageName } from "sagas/selectors";
import type { AppState } from "ee/reducers";
import { getShowWorkflowFeature } from "ee/selectors/workflowSelectors";
import type { ActionDataState } from "ee/reducers/entityReducers/actionsReducer";
import { getNextEntityName } from "utils/AppsmithUtils";
import { objectKeys } from "@appsmith/utils";
import { FEATURE_FLAG } from "ee/entities/FeatureFlag";
import { useFeatureFlag } from "utils/hooks/useFeatureFlag";
import { getIDETypeByUrl } from "ee/entities/IDE/utils";

const icon = WorkflowIcon();

export const createNewHITLQueryName = (
  actions: ActionDataState,
  entityId: string,
  key: CreateNewActionKeyInterface = CreateNewActionKey.WORKFLOW,
) => {
  const workflowHITLQueryNames = actions // TODO: Fix this the next time the file is edited
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .filter((a: any) => a.config[key] === entityId)
    .map((a) => a.config.name);

  return getNextEntityName("HITLQuery", workflowHITLQueryNames);
};

export const createNewWorkflowQueryName = (
  actions: ActionDataState,
  entityId: string,
  key: CreateNewActionKeyInterface = CreateNewActionKey.PAGE,
) => {
  const pageWorkflowsQueryNames = actions // TODO: Fix this the next time the file is edited
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .filter((a: any) => a.config[key] === entityId)
    .map((a) => a.config.name);

  return getNextEntityName("WorkflowsQuery", pageWorkflowsQueryNames);
};

export const useWorkflowOptions = () => {
  const allWorkflows = useSelector(getWorkflowsList);
  const workflowPlugin = useSelector((state: AppState) =>
    getPluginIdOfPackageName(state, PluginPackageName.WORKFLOW),
  );
  const showWorkflowFeature = useSelector(getShowWorkflowFeature);

  const isHITLQueryEnabled = useFeatureFlag(
    FEATURE_FLAG.release_workflows_hitl_query_enabled,
  );

  const workflowOptions: ActionOperation[] = [];
  const ideType = getIDETypeByUrl(window.location.pathname);

  if (!workflowPlugin) {
    return workflowOptions;
  }

  if (ideType === IDE_TYPE.Workflow && isHITLQueryEnabled) {
    workflowOptions.push({
      title: "HITL Query",
      desc: "Create a new HITL query",
      isBeta: true,
      icon,
      kind: SEARCH_ITEM_TYPES.actionOperation,
      action: (
        entityId: string,
        location: EventLocation,
        entityType?: ActionParentEntityTypeInterface,
      ) =>
        createWorkflowExecutionActionBasedOnParentEntityType(
          entityId,
          location,
          entityType,
        ),
      focusEntityType: FocusEntity.QUERY,
    });
  } else if (
    showWorkflowFeature &&
    // We don't want to show the workflow query option if the user is already in the workflow editor
    ideType !== IDE_TYPE.Workflow &&
    objectKeys(allWorkflows).length > 0
  ) {
    workflowOptions.push({
      title: "Workflows Query",
      desc: "Create a new workflow query",
      isBeta: true,
      icon,
      kind: SEARCH_ITEM_TYPES.actionOperation,
      action: (
        entityId: string,
        location: EventLocation,
        entityType?: ActionParentEntityTypeInterface,
      ) =>
        createWorkflowExecutionActionBasedOnParentEntityType(
          entityId,
          location,
          entityType,
        ),
      focusEntityType: FocusEntity.QUERY,
    });
  }

  return workflowOptions;
};

// We don't want to show the create new JS object option if the user is in the workflow editor
// this is done since worflows runner doesn't support multiple JS objects
// TODO: Remove this once workflows can support multiple JS objects
export const checkIfJSObjectCreationAllowed = () => {
  const ideType = getIDETypeByUrl(window.location.pathname);

  return ideType === IDE_TYPE.Workflow;
};
