// TODO: this component is being used in src/ce/pages/common/AppHeader.tsx

import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import { useSelector } from "react-redux";
import { EditInteractionKind, SavingState } from "@appsmith/ads-old";
import { Button, IDEHeader, IDEHeaderTitle, Link } from "@appsmith/ads";
import { getTheme, ThemeMode } from "selectors/themeSelectors";
import { EditorSaveIndicator } from "pages/Editor/EditorSaveIndicator";
import {
  createMessage,
  DEPLOY_MENU_OPTION,
  HEADER_TITLES,
} from "ee/constants/messages";
import { AppsmithLink } from "pages/Editor/AppsmithLink";
import EditorName from "pages/Editor/EditorName";
import { useNavigationMenuData } from "./hooks";
import {
  getCurrentWorkflow,
  getIsSavingWorkflowName,
  getIsWorkflowPublishing,
  getisErrorSavingWorkflowName,
} from "ee/selectors/workflowSelectors";
import {
  publishWorkflow,
  updateWorkflowName,
} from "ee/actions/workflowActions";
import type { Workflow } from "ee/constants/WorkflowConstants";
import { useDispatch } from "react-redux";
import { APPLICATIONS_URL } from "constants/routes";
import { EditorState } from "IDE/enums";
import { useCurrentWorkflowState } from "../../hooks";
import { getCurrentAppWorkspace } from "ee/selectors/selectedWorkspaceSelectors";

const theme = getTheme(ThemeMode.LIGHT);

interface HeaderTitleProps {
  ideState: EditorState;
}

const HeaderTitleComponent = ({ ideState }: HeaderTitleProps) => {
  switch (ideState) {
    case EditorState.DATA:
      return (
        <IDEHeaderTitle
          key={ideState}
          title={createMessage(HEADER_TITLES.DATA)}
        />
      );
    case EditorState.EDITOR:
      return (
        <IDEHeaderTitle
          key={ideState}
          title={createMessage(HEADER_TITLES.WORKFLOWS_EDITOR)}
        />
      );
    case EditorState.SETTINGS:
      return (
        <IDEHeaderTitle
          key={ideState}
          title={createMessage(HEADER_TITLES.SETTINGS)}
        />
      );
    default:
      return (
        <IDEHeaderTitle
          key={ideState}
          title={createMessage(HEADER_TITLES.WORKFLOWS_EDITOR)}
        />
      );
  }
};

export function Header() {
  const dispatch = useDispatch();
  const isSavingName = useSelector(getIsSavingWorkflowName);
  const isErroredSavingName = useSelector(getisErrorSavingWorkflowName);
  // TODO: Fix this the next time the file is edited
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const workflowList: any = [];
  const currentWorkflow = useSelector(getCurrentWorkflow);
  const workflowId = currentWorkflow?.id;
  const isPublishing = useSelector(getIsWorkflowPublishing);
  const workflowState = useCurrentWorkflowState();
  const currentWorkspace = useSelector(getCurrentAppWorkspace);

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const updateWorkflow = (val: string, workflow: Workflow | null) => {
    if (val !== workflow?.name) {
      dispatch(updateWorkflowName(val, workflow?.id || ""));
    }
  };

  const onPublishWorkflow = () => {
    if (workflowId) {
      dispatch(publishWorkflow({ workflowId }));
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <IDEHeader>
        <IDEHeader.Left logo={<AppsmithLink />}>
          <HeaderTitleComponent ideState={workflowState} />
          <EditorSaveIndicator
            isSaving={isSavingName}
            saveError={isErroredSavingName}
          />
        </IDEHeader.Left>
        <IDEHeader.Center>
          {currentWorkspace?.name && (
            <>
              <Link className="mr-1.5" to={APPLICATIONS_URL}>
                {currentWorkspace?.name}
              </Link>
              {"/"}
              <EditorName
                className="t--workflow-name editable-worflow-name max-w-48"
                defaultSavingState={
                  isSavingName ? SavingState.STARTED : SavingState.NOT_STARTED
                }
                defaultValue={currentWorkflow?.name || ""}
                editInteractionKind={EditInteractionKind.SINGLE}
                editorName="Workflow"
                fill
                getNavigationMenu={useNavigationMenuData}
                isError={isErroredSavingName}
                isNewEditor={
                  // TODO: Fix this the next time the file is edited
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  workflowList.filter((el: any) => el.id === workflowId)
                    .length > 0
                }
                isPopoverOpen={isPopoverOpen}
                onBlur={(value: string) =>
                  updateWorkflow(value, currentWorkflow)
                }
                setIsPopoverOpen={setIsPopoverOpen}
              />
            </>
          )}
        </IDEHeader.Center>
        <IDEHeader.Right>
          <Button
            data-testid="t--workflow-publish-btn"
            isLoading={isPublishing}
            kind="tertiary"
            onClick={onPublishWorkflow}
            size="md"
            startIcon={"rocket"}
          >
            {createMessage(DEPLOY_MENU_OPTION)}
          </Button>
        </IDEHeader.Right>
      </IDEHeader>
    </ThemeProvider>
  );
}
